import { createStore, applyMiddleware, compose } from 'redux';
// import createThunkMiddleware from 'redux-thunk';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { projectConfig } from '../constants/defaultValues';
// Instead of loading axios in every Actions files, we can load it here and pass on.
import axios from 'axios';
projectConfig.axios = axios;

// const thunk = createThunkMiddleware();
// const middlewares = [createThunkMiddleware];

export function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(thunk.withExtraArgument(projectConfig)))
  );

  // sagaMiddleware.run(sagas);

  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     const nextRootReducer = require('./reducers');
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
}
