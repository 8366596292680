/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import {
  TOPICS_GET_DETAILS,
  TOPICS_GET_DETAILS_SUCCESS,
  TOPICS_GET_DETAILS_ERROR,
  TOPICS_UPLOAD_FILE_DATA_SUCCESS,
  TOPICS_UPLOAD_FILE_DATA_ERROR,
  TOPICS_ADD_NEW,
  TOPICS_UPLOAD_FILE_PROGRESS,
  TOPICS_DELETE,
  TOPICS_EMPTY,
  TOPICS_UPDATE,
} from "../actions";

export const getTopicsDetails = () => ({
  type: TOPICS_GET_DETAILS,
});
export const emptyTopics = () => ({
  type: TOPICS_EMPTY,
});

export const addNewTopic = (item) => ({
  type: TOPICS_ADD_NEW,
  payload: item,
});

export const getTopicsDetailsSuccess = (items) => ({
  type: TOPICS_GET_DETAILS_SUCCESS,
  payload: items,
});

export const getTopicsDetailsError = (error) => ({
  type: TOPICS_GET_DETAILS_ERROR,
  payload: error,
});
export const actionUpdateTopicDetails = (item) => ({
  type: TOPICS_UPDATE,
  payload: item,
});

export const uploadFileDataSuccess = (fileData) => ({
  type: TOPICS_UPLOAD_FILE_DATA_SUCCESS,
  payload: fileData,
});

export const uploadFileDataError = (error) => ({
  type: TOPICS_UPLOAD_FILE_DATA_ERROR,
  payload: error,
});

export const fileProgress = (fileProgress) => ({
  type: TOPICS_UPLOAD_FILE_PROGRESS,
  payload: fileProgress,
});

export const actionDeleteTopicDetails = (itemId) => ({
  type: TOPICS_DELETE,
  payload: itemId,
});

// Here as per React-Redux we need to return only objects (i.e. Action objects)
// But, with the help of REDUX-THUNK, we can return a function instead of an object like below.
// And, we can access the function in the Component directly....
export const fetchTopicsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getTopicsDetails());
    return axios.get(baseUrl + endpoints.getTopics,headers)
      .then(response => {
        return response.data;
      })
      .then((data) => {
        dispatch(getTopicsDetailsSuccess(data.response.data));
      })
      .catch(() => {
        dispatch(
          getTopicsDetailsError(
            "Something went wrong. Try again after sometime."
          )
        );
      });
  };
};

export const fetchTopicsDetailsByChapter = (chapterData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.post(baseUrl + endpoints.getTopicsByChapter, { "chapterId": parseInt(chapterData.chapterId) },headers)
      .then(response => {
        return response.data;
      })
      .then((data) => {
        dispatch(getTopicsDetailsSuccess(data.response.data));
      })
      .catch(() => {
        dispatch(
          getTopicsDetailsError(
            "Something went wrong. Try again after sometime."
          )
        );
      });
  };
};

export const fetchTopicsDetailsByChapterForSearch = (chapterData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.post(baseUrl + endpoints.getTopicsByChapter, { "chapterId": chapterData },headers)
      .then(response => {
        
        return response.data;
      })
      .then((data) => {
        dispatch(getTopicsDetailsSuccess(data.response.data));
      })
      .catch(() => {
        dispatch(
          getTopicsDetailsError(
            "Something went wrong. Try again after sometime."
          )
        );
      });
  };
};

export const saveNewTopic = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.postTopics, postData,headers)
      .then(response => {
        return response.data;
      })
      .then((data) => {
        dispatch(addNewTopic(data.response.data[0]));
        toast.configure();
        toast.success("Successfully Added", { autoClose: 3000 });
        window.location.reload();
      })
      .catch((error) => {
        dispatch(
          getTopicsDetailsError(
            "Something went wrong. Try again after sometime."
          )
        );
      });
  };
};

export const uploadFiles = (fileData) => {
  return (
    dispatch,
    getState,
    { axios, baseUrl, endpoints, uploadFileHeaders }
  ) => {
    dispatch(uploadFileDataSuccess(""));
    return axios
      .post(baseUrl + endpoints.uploadFile, fileData, uploadFileHeaders)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(uploadFileDataSuccess(data));
      })
      .catch((err) => {
        dispatch(
          uploadFileDataSuccess(
            "Could not upload file. Please try again after sometime."
          )
        );
      });
  };
};

export const updateTopics = (topicData) => {
  return (
    dispatch,
    getState,
    { axios, baseUrl, endpoints, uploadFileHeaders }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .put(baseUrl + endpoints.updateTopics, topicData, {
          headers: uploadFileHeaders.headers,
          onUploadProgress: function (progressEvent) {
            // Do whatever you want with the native progress event
            dispatch(
              fileProgress((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch(uploadFileDataSuccess(data.response.data.topicVideoUrl));
          dispatch(fileProgress(0));
          resolve();
          toast.configure();
          toast.success("Successfully Uploaded", { autoClose: 3000 });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          dispatch(
            uploadFileDataSuccess(
              "Could not upload file. Please try again after sometime."
            )
          );
          dispatch(fileProgress(0));
          resolve(err);
        });
    });
  };
};

export const updateTopicsTest = (topicData) => {
  return (
    dispatch,
    getState,
    { axios, baseUrl, endpoints, uploadFileHeaders }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + endpoints.updateTopicsTest, topicData, {
          headers: uploadFileHeaders.headers,
          onUploadProgress: function (progressEvent) {
            dispatch(
              fileProgress((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log("excel data", data.response.duplicateQuestions);
          // dispatch(uploadFileDataSuccess(data.response.duplicateQuestions));
          dispatch(fileProgress(0));
          resolve();
          toast.configure();
          toast.success("Successfully Uploaded", { autoClose: 4000 });
          window.location.reload();
        })
        .catch((err) => {
          toast.configure();
          toast.error("Please upload valid excel", { autoClose: 3000 });
          dispatch(
            uploadFileDataError(
              "Could not upload file. Please try again after sometime."
            )
          );
          dispatch(fileProgress(0));
          resolve(err);
        });
    });
  };
};
//Delete
export const deleteTopicDetails = (deleteTopicData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.delete(baseUrl + endpoints.deleteTopics, { data: deleteTopicData },headers)
      .then(response => {
        return response.data;
      })
      .then((data) => {
        dispatch(actionDeleteTopicDetails(data.response.data.topicId));
        toast.configure();
        toast.success("Successfully Deleted", { autoClose: 2000 });
      })
      .catch((error) => {
        dispatch(
          getTopicsDetailsError(
            "Something went wrong. Try again after sometime."
          )
        );
      });
  };
};

//put
export const updateTopicName = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.updateTopicName, { "topicId": postData.topicId, "topicTitle": postData.topicTitle,"priority":postData.priority },headers)
      .then(response => {
        return response.data;
      })
      .then((data) => {
        dispatch(actionUpdateTopicDetails(data.response.data));
        toast.configure();
        toast.success("Successfully Updated", { autoClose: 2000 });
        window.location.reload();
      })
      .catch((error) => {
        dispatch(
          getTopicsDetailsError(
            "Something went wrong. Try again after sometime."
          )
        );
      });
  };
};
