/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';

import {

    ADD_NEW_SUBSCRIPTIONS_SUCCES,ADD_NEW_SUBSCRIPTIONS_FAIL

} from '../actions';

export const addNewSubscriptionsDetailsSuccess = (item) => ({
  type: ADD_NEW_SUBSCRIPTIONS_SUCCES,
  payload: item
});
export const addNewSubscriptionsDetailsFail = (item) => ({
    type: ADD_NEW_SUBSCRIPTIONS_FAIL,
    payload: item
  });

//post
export const saveBulkSubDetails = (postData) => {
    console.log('reached');
    
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.post(baseUrl + endpoints.bulkSubupload, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
          console.log('success',data);
         dispatch(addNewSubscriptionsDetailsSuccess(data.response.addSuscriptions));
         toast.configure();
         toast.success('Successfully Added', { autoClose: 2000 })
        // window.location.reload();
      }).catch((error) => {
          console.log('error' ,error);
        toast.error('Error');
        dispatch(addNewSubscriptionsDetailsFail('Something went wrong. Try again after sometime.'));
      });
  }
};


