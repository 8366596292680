import { toast } from 'react-toastify';

import {
    ADD_ADMIN_DETAILS,
    GET_ADMIN_DETAILS,
    ADMINDETAILS_SUCCESS,
    ADMINDETAILS_FAIL,
    GET_ADMINDETAILS_FAIL,
    GET_ADMINDETAILS_SUCCES,DELETE_ADMINDETAILS_FAIL

} from '../actions';

export const getAdminDetails = () => ({
    type: GET_ADMIN_DETAILS,
  });
  export const adminDetailsSuccess = (item) => ({
    type: ADMINDETAILS_SUCCESS,
    payload: item
  });
  export const adminDetailsFail = (item) => ({
    type: ADMINDETAILS_FAIL,
    payload: item
  });
  export const getAdminDetailsSuccess = (item) => ({
    type: GET_ADMINDETAILS_SUCCES,
    payload: item
  });
  export const getAdminDetailsFail = (item) => ({
    type: GET_ADMINDETAILS_FAIL,
    payload: item
  });
  export const deleteAdminDetailsFail = (item) => ({
    type: DELETE_ADMINDETAILS_FAIL,
    payload: item
  });

export const adminDetails = (postData) => {
    console.log('edit info',postData);
      return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
        return axios.post(baseUrl + endpoints.admin, postData,headers)
          .then(response => {
            return response.data;
          }).then(data => {
            console.log('add response',data.response.data);
            //dispatch(adminDetailsSuccess(data.response.data[0]));
            toast.configure()
            toast.success('Successfully Added', { autoClose: 2000 })
            window.location.reload();
          }).catch(() => {
            dispatch(adminDetailsFail('Something went wrong. Try again after sometime.'));
          });
    };
    };
    export const deleteAdminDetails =(adminData) =>{
        return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
          return axios.delete(baseUrl + endpoints.admin, { data: adminData },headers)
            .then(response => {
              return response.data;
            }).then(data => {
              toast.configure()
              toast.success('Successfully Deleted', { autoClose: 2000 })
              window.location.reload();
            }).catch((error) => {
              dispatch(deleteAdminDetailsFail('Something went wrong. Try again after sometime.'));
            });
        }
      
    }

    export const fetchAdminDetails = () => {
        return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
      
          dispatch(getAdminDetails());
      
          return axios.get(baseUrl + endpoints.admin,headers)
            .then(response => {
              return response.data;
            }).then(data => {
              console.log('adminData',data.response.data)
              dispatch(getAdminDetailsSuccess(data.response.data));
            }).catch(() => {
              dispatch(getAdminDetailsFail('Something went wrong. Try again after sometime.'));
            });
        }
      };