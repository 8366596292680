import {
    PAPER_ADD_NEW,GET_ALL_PREVIOUS_PAPERS_SUCCES,GET_ALL_PREVIOUS_PAPERS,GET_ALL_PREVIOUS_PAPERS_FAIL,PAPERS_DELETE,PAPERS_GET_DETAILS_ERROR, PAPER_EDIT

} from '../actions';

const INIT_STATE = {
    papers: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    console.log(action.payload);

    switch (action.type) {
        case PAPER_ADD_NEW:
            return {
                ...state,
                papers: [...state.papers, action.payload]
            }
        case PAPER_EDIT:
            const updatedItems = state.papers.map(paper => {
                if (paper.paperId === action.payload.paperId) {
                    return { ...paper, ...action.payload }
                }
                return paper
            })
            return {
                ...state,
                boards: updatedItems

            }
       
        case GET_ALL_PREVIOUS_PAPERS: 
                         return { ...state, loading: true };
        
        case GET_ALL_PREVIOUS_PAPERS_SUCCES:
            return { ...state, loading: false, papers: action.payload, error: '' };
        case PAPERS_DELETE:
            return {
                ...state,
                papers: state.papers.filter(paper => paper.paperId !== action.payload)

            }

        case PAPERS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, papers: [] 
            };
        case GET_ALL_PREVIOUS_PAPERS_FAIL :
            return { ...state, loading: false, error: action.payload, papers: [] 
            };
        
        default:
            return { ...state };
    }
};