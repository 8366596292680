import {
    SUBSCRIPTION_MGMT_OFFER_ADD_NEW,
    SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_SUCCESS,
    SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_ERROR,
    SUBSCRIPTION_MGMT_OFFER_GET_DETAILS,
    SUBSCRIPTION_MGMT_OFFER_DELETE,
    SUBSCRIPTION_MGMT_OFFER_UPDATE
} from '../actions';

const INIT_STATE = {
    offerDetails: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBSCRIPTION_MGMT_OFFER_GET_DETAILS:
            return { ...state, loading: true };

        case SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, offerDetails: action.payload, error: '' };

        case SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, offerDetails: [] };

        case SUBSCRIPTION_MGMT_OFFER_ADD_NEW:
            return {
                ...state,
                offerDetails: [...state.offerDetails, action.payload]
            }
        case SUBSCRIPTION_MGMT_OFFER_DELETE:
            return {
                ...state,
                offerDetails: state.offerDetails.filter(offerDetail => offerDetail.offerId !== action.payload)

            }
        case SUBSCRIPTION_MGMT_OFFER_UPDATE: {
            const updatedItems = state.offerDetails.map(offerDetail => {
                if (offerDetail.offerId === action.payload.offerId) {
                    return { ...offerDetail, ...action.payload }
                }
                return offerDetail
            })
            return {
                ...state,
                offerDetails: updatedItems

            }
        }

        default:
            return { ...state };
    }
};