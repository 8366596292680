/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import {
  ALL_STUDENTS_INFO,
  ALL_STUDENTS_INFO_SUCCESS,
  ALL_STUDENTS_INFO_ERROR,
  STUDENT_SEARCH_BY_MOBILE,
  SUBSCRIPTION_GET_DETAILS_BY_STUDENT,
  SUBSCRIPTION_GET_DETAILS_BY_STUDENT_SUCCESS,
  SUBSCRIPTION_GET_DETAILS_BY_STUDENT_FAIL,
  DELETE_STUDENT
} from '../actions';
export const getAllStudents = () => ({
  type: ALL_STUDENTS_INFO
});

export const getAllStudentsSuccess = (items) => ({
  type: ALL_STUDENTS_INFO_SUCCESS,
  payload: items
});

export const getAllStudentsError = (error) => ({
  type: ALL_STUDENTS_INFO_ERROR,
  payload: error,
});
export const getStudentsByMobile = (item) => ({
  type: STUDENT_SEARCH_BY_MOBILE,
  payload: item
});
export const getSubscriptionsByStudent = (item) =>({
  type : SUBSCRIPTION_GET_DETAILS_BY_STUDENT,
  payload : item
})
export const fetchSubscriptionsByStudentSuccess = (item) => ({
  type: SUBSCRIPTION_GET_DETAILS_BY_STUDENT_SUCCESS,
  payload : item
});
export const fetchSubscriptionsByStudentFail = (item) => ({
  type: SUBSCRIPTION_GET_DETAILS_BY_STUDENT_FAIL,
  payload: item
});
export const deleteSubscription = (postData) => {
  console.log('post',postData);
    return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
      return axios.delete(baseUrl + endpoints.subscription, { data: postData },headers)
        .then(response => {
          return response.data;
        }).then(data => {
            console.log('success',data);
          // dispatch(addNewSubscriptionsDetailsSuccess(data.response.addSuscriptions));
           toast.configure();
           toast.success('Successfully Deleted', { autoClose: 2000 })
           window.location.reload();
        }).catch((error) => {
            console.log('error' ,error);
          toast.error('Error');
          //dispatch(addNewSubscriptionsDetailsFail('Something went wrong. Try again after sometime.'));
        });
    }
  }
export const fetchSubscriptionsByStudent = (stdId) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getSubscriptionsByStudent());
    return axios.post(baseUrl + endpoints.subscriptionbystudent,{'studentId':stdId},headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(fetchSubscriptionsByStudentSuccess(data.response.data));
      }).catch(() => {
        dispatch(fetchSubscriptionsByStudentFail('Something went wrong. Try again after sometime.'));
      });
  }
};
export const fetchAllStudentsExportData = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getAllStudents());
    return axios.get(baseUrl + endpoints.exportSubscriptions,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        
        const Keys = Object.keys(data.response.data);
        let modifieddata=[];
        Keys.forEach(key => {
          var formate =data.response.data[key][0];
           if(data.response.data[key].findIndex((sub)=> sub.subrId) >-1)
           {
          formate.subscriptions= data.response.data[key];
           }
          modifieddata.push(formate);          
        });
        console.log('mod',modifieddata)
        dispatch(getAllStudentsSuccess(modifieddata));
      }).catch(() => {
        dispatch(getAllStudentsError('Something went wrong. Try again after sometime.'));
      });
  }
};
export const deletesStudentDetailsAction = (itemId) => ({
  type: DELETE_STUDENT,
  payload: itemId
});

export const fetchAllStudentsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getAllStudents());
    return axios.get(baseUrl + endpoints.getAllStudents,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getAllStudentsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getAllStudentsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchAllStudentsDetailsByMobile = (mobilenum) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getAllStudents());
    return axios.post(baseUrl + endpoints.getAllStudentsByMobile, { "studentPhone": mobilenum },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getAllStudentsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getAllStudentsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//Delete
export const deleteStudentDetails = (deleteStudentData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return axios.post(baseUrl + endpoints.deleteStudent, {
      studentId: deleteStudentData.studentId
     })
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deletesStudentDetailsAction(data.response.data.studentId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch(() => {
        dispatch(getAllStudentsError('Something went wrong. Try again after sometime.'));
      });
  }
};
