/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ADMIN_DETAILS,ADMINDETAILS_FAIL,ADMINDETAILS_SUCCESS,GET_ADMINDETAILS_SUCCES, GET_ADMINDETAILS_FAIL,DELETE_ADMINDETAILS_FAIL    

} from '../actions';

const INIT_STATE = {
    admins: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    console.log('action',action);
    switch (action.type) {
        case GET_ADMIN_DETAILS:
            return { ...state, loading: true };

        case ADMINDETAILS_SUCCESS:
            return {
                ...state,
                admins: [...state.admins, action.payload]
            }
        case GET_ADMINDETAILS_SUCCES:
            return { ...state, loading: false, admins: action.payload, error: '' };
        
        case GET_ADMINDETAILS_FAIL:
                return { ...state, loading: false, admins: [], error: 'nO DATA FOUND' };
        case ADMINDETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_ADMINDETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return { ...state };
    }
};