import { toast } from "react-toastify";
import {
  GENERATE_URL_INFO,
  GENERATE_URL_SUCCESS,
  GENERATE_URL_ERROR,
  GENERATE_URL_PROGRESS,
} from "../actions";

export const handleGenerateUrlInfo = () => ({
  type: GENERATE_URL_INFO,
});

export const handleGenerateUrlSuccess = (info) => ({
  type: GENERATE_URL_SUCCESS,
  payload: info,
});

export const handleGenerateUrlError = (error) => ({
  type: GENERATE_URL_ERROR,
  payload: error,
});

export const handleGenerateUrlProgress = (fileProgress) => ({
  type: GENERATE_URL_PROGRESS,
  payload: fileProgress,
});

// Generate URL Feature Action
export const handleMeidaGenerateUrl = (data) => {
  return (
    dispatch,
    getState,
    { axios, baseUrl, endpoints, uploadFileHeaders }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + endpoints.uploadFile, data, {
          headers: uploadFileHeaders.headers,
          onUploadProgress: function (progressEvent) {
            dispatch(
              handleGenerateUrlProgress(
                (progressEvent.loaded / progressEvent.total) * 100
              )
            );
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          dispatch(handleGenerateUrlSuccess(data));
          dispatch(handleGenerateUrlProgress(0));
          resolve();
          toast.configure();
          toast.success("Successfully Uploaded", { autoClose: 3000 });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        })
        .catch((err) => {
          dispatch(
            handleGenerateUrlSuccess(
              "Could not upload file. Please try again after sometime."
            )
          );
          dispatch(handleGenerateUrlProgress(0));
          resolve(err);
        });
    });
  };
};
