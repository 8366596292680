import {
    SUBJECTS_GET_DETAILS,
    SUBJECTS_GET_DETAILS_SUCCESS,
    SUBJECTS_GET_DETAILS_ERROR,
    SUBJECTS_ADD_NEW,
    SUBJECTS_DELETE,
    SUBJECTS_UPDATE,
    SUBJECTS_EMPTY
} from '../actions';

const INIT_STATE = {
    subjects: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBJECTS_GET_DETAILS:
            return { ...state, loading: true };

        case SUBJECTS_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, subjects: action.payload, error: '' };

        case SUBJECTS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, subjects: [] };

        case SUBJECTS_ADD_NEW:
            return {
                ...state,
                subjects: [...state.subjects, action.payload]
            }

        case SUBJECTS_DELETE:
            return {
                ...state,
                subjects: state.subjects.filter(subject => subject.subjectId !== action.payload)

            }
        case SUBJECTS_UPDATE: {
            const updatedItems = state.subjects.map(subject => {
                if (subject.subjectId === action.payload.subjectId) {
                    return { ...subject, ...action.payload }
                }
                return subject
            })
            return {
                ...state,
                subjects: updatedItems

            }
        }
        case SUBJECTS_EMPTY:
            return { ...state, loading: false, error: '', subjects: [] };


        default:
            return { ...state };
    }
};