import {
    ALL_STUDENTS_INFO,
    ALL_STUDENTS_INFO_SUCCESS,
    ALL_STUDENTS_INFO_ERROR,
    STUDENT_SEARCH_BY_MOBILE,
    SUBSCRIPTION_GET_DETAILS_BY_STUDENT,
    SUBSCRIPTION_GET_DETAILS_BY_STUDENT_SUCCESS,
    SUBSCRIPTION_GET_DETAILS_BY_STUDENT_FAIL,DEL_SUBSCRIPTION_SUCCESS,
    DELETE_STUDENT
} from '../actions';

const INIT_STATE = {
    allStudents: [],
    allStudentsLoading: false,
    allStudentsError: '',
    subscriptiondetailsByStudent: [],
    error:''

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case ALL_STUDENTS_INFO:
            return { ...state, loading: true };

        case ALL_STUDENTS_INFO_SUCCESS:
            console.log(action.payload)
            return { ...state, loading: false, allStudents: action.payload, allStudentsError: '' };

        case ALL_STUDENTS_INFO_ERROR:
            return { ...state, loading: false, allStudentsError: action.payload, allStudents: [] };

        case STUDENT_SEARCH_BY_MOBILE: {
            const updatedItems = state.allStudents.map(std => {
                if (std.studentPhone === action.payload.studentPhone) {
                    return { ...std, ...action.payload }
                }
                return std
            })
            return {
                ...state,
                allStudents: updatedItems

            }
        }
        case SUBSCRIPTION_GET_DETAILS_BY_STUDENT:{
            return { ...state, loading: true };
        }
        case DEL_SUBSCRIPTION_SUCCESS:{
            return {
                ...state,
                subscriptiondetailsByStudent: state.subscriptiondetailsByStudent.filter(subrData => subrData.subrId !== action.payload.subrId)

            }
        }
        
        case SUBSCRIPTION_GET_DETAILS_BY_STUDENT_SUCCESS : {
            return { ...state, loading: false, subscriptiondetailsByStudent: action.payload, getSubscriptionbystudenterror: '' };
        }
        case SUBSCRIPTION_GET_DETAILS_BY_STUDENT_FAIL : {
            return { ...state, loading: false, getSubscriptionbystudenterror: action.payload, subscriptiondetailsByStudent: [] };
        }
        case DELETE_STUDENT:
            return {
                ...state,
                allStudents: state.allStudents.filter(stdnt => stdnt.studentId !== action.payload)

            }
        default:
            return { ...state };
    }
};