import {
    CATEGORY_GET_DETAILS,
    CATEGORY_GET_DETAILS_SUCCESS,
    CATEGORY_GET_DETAILS_ERROR,
    ADD_NEW_CONTENT,
    CONTENT_GET_DETAILS,
    CONTENT_GET_DETAILS_SUCCESS,
    CONTENT_GET_DETAILS_ERROR,
    DELETE_CONTENT,
    UPDATE_CONTENT

} from '../actions';

const INIT_STATE = {
    categories: [],
    contents: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CATEGORY_GET_DETAILS:
            return { ...state, loading: true };

        case CATEGORY_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, categories: action.payload, error: '' };

        case CATEGORY_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, categories: [] };
        case CONTENT_GET_DETAILS:
            return { ...state, loading: true };

        case CONTENT_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, contents: action.payload, error: '' };

        case CONTENT_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, contents: [] };

        case ADD_NEW_CONTENT:
            return {
                ...state,
                contents: [...state.contents, action.payload]
            };
        case DELETE_CONTENT:
            return {
                ...state,
                contents: state.contents.filter(content => content.contentId !== action.payload)

            }
        case UPDATE_CONTENT: {
            const updatedItems = state.contents.map(content => {
                if (content.contentId === action.payload.contentId) {
                    return { ...content, ...action.payload }
                }
                return content
            })
            return {
                ...state,
                contents: updatedItems

            }
        }
        default:
            return { ...state };
    }
};