/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import { baseUrl } from '../../constants/defaultValues';

import {
  UPLOADTEST_GET_DETAILS,
  UPLOADTEST_GET_DETAILS_SUCCESS,
  UPLOADTEST_GET_DETAILS_ERROR,
  UPLOADTEST_DELETE
} from '../actions';

export const getUploadTestDetails = () => ({
  type: UPLOADTEST_GET_DETAILS
});


export const getUploadTestDetailsSuccess = (items) => ({
  type: UPLOADTEST_GET_DETAILS_SUCCESS,
  payload: items
});

export const getUploadTestDetailsError = (error) => ({
  type: UPLOADTEST_GET_DETAILS_ERROR,
  payload: error,
});

export const deleteUploadTestAction = (itemId) => ({
  type: UPLOADTEST_DELETE,
  payload: itemId
});

export const fetchUploadTestDetails = (topicInfo) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.getallquestions, { "topicId": topicInfo.topicId, "chapterId": topicInfo.chapterId },headers)
      .then(response => {
        console.log(response.data)
        return response.data;
      }).then(data => {
        dispatch(getUploadTestDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getUploadTestDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const deleteUploadTestDetails = (topicData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.deleteTests, { "topicId": topicData.topicId, "chapterId": topicData.chapterId },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deleteUploadTestAction(data.response.data));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getUploadTestDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

