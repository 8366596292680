import {
    BOARDS_GET_DETAILS,
    BOARDS_GET_DETAILS_SUCCESS,
    BOARDS_GET_DETAILS_ERROR,
    BOARDS_ADD_NEW,
    BOARDS_DELETE,
    BOARDS_UPDATE,
    BOARDS_EMPTY

} from '../actions';

const INIT_STATE = {
    boards: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BOARDS_GET_DETAILS:
            return { ...state, loading: true };

        case BOARDS_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, boards: action.payload, error: '' };

        case BOARDS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, boards: [] };

        case BOARDS_ADD_NEW:
            return {
                ...state,
                boards: [...state.boards, action.payload]
            }

        case BOARDS_DELETE:
            return {
                ...state,
                boards: state.boards.filter(board => board.boardId !== action.payload)

            }

        case BOARDS_UPDATE: {
            const updatedItems = state.boards.map(board => {
                if (board.boardId === action.payload.boardId) {
                    return { ...board, ...action.payload }
                }
                return board
            })
            return {
                ...state,
                boards: updatedItems

            }
        }
        case BOARDS_EMPTY:
            return { ...state, loading: false, error: '', boards: [] };



        default:
            return { ...state };
    }
};