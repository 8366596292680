import {
    NOTIFICATIONS_GET_DETAILS_SUCCESS, NOTIFICATIONS_GET_DETAILS_ERROR,
    NOTIFICATIONS_ADD_DETAILS_SUCCESS,
    NOTIFICATIONS_DELETE
  } from '../actions';

const INIT_STATE = {
    notificationDetails: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case NOTIFICATIONS_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, notificationDetails: action.payload, error: '' };

        case NOTIFICATIONS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload};

        case NOTIFICATIONS_ADD_DETAILS_SUCCESS:
            return {
                ...state,
                notificationDetails: (action.payload.notifyid) ? [...state.notificationDetails, action.payload] : state.notificationDetails
            }
        
        case NOTIFICATIONS_DELETE:
            return {
                ...state,
                notificationDetails: state.notificationDetails.filter(details => details.notifyid !== action.payload)
            }

        default:
            return { ...state };
    }
};