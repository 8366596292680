/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  SUBJECTS_GET_DETAILS,
  SUBJECTS_GET_DETAILS_SUCCESS,
  SUBJECTS_GET_DETAILS_ERROR,
  SUBJECTS_ADD_NEW,
  SUBJECTS_DELETE,
  SUBJECTS_UPDATE,
  SUBJECTS_EMPTY
} from '../actions';
import { toast } from 'react-toastify';

export const getSubjectsDetails = () => ({
  type: SUBJECTS_GET_DETAILS
});

export const emptySubjects = () => ({
  type: SUBJECTS_EMPTY
});
export const getSubjectsDetailsSuccess = (items) => ({
  type: SUBJECTS_GET_DETAILS_SUCCESS,
  payload: items
});

export const actionAddNewSubjectDetails = (item) => ({
  type: SUBJECTS_ADD_NEW,
  payload: item
});

export const ActionDeleteSubjectDetails = (itemId) => ({
  type: SUBJECTS_DELETE,
  payload: itemId
});

export const getSubjectsDetailsError = (error) => ({
  type: SUBJECTS_GET_DETAILS_ERROR,
  payload: error,
});

export const actionUpdateSubjectsDetails = (item) => ({
  type: SUBJECTS_UPDATE,
  payload: item
});
// Here as per React-Redux we need to return only objects (i.e. Action objects)
// But, with the help of REDUX-THUNK, we can return a function instead of an object like below.
// And, we can access the function in the Component directly....
export const fetchSubjectsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getSubjectsDetails());
    return axios.get(baseUrl + endpoints.getSubjects,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getSubjectsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//post
export const saveSubjectDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.postSubjects, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionAddNewSubjectDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 3000 })
        window.location.reload();
      }).catch((error) => {
        //console.log(error);
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//Delete
export const deleteSubjectsDetails = (deleteSubjectData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.delete(baseUrl + endpoints.deleteSubjects, { data: deleteSubjectData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(ActionDeleteSubjectDetails(data.response.data.subjectId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchSubjectsDetailsByClass = (classData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.getSubjectsByClass, { "classId": parseInt(classData.classId) },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getSubjectsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//put
export const updateSubjectsDetails = (postData) => {
  delete postData.boardName;
  delete postData.className;
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.updateSubjects, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionUpdateSubjectsDetails(data.response.data));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
        window.location.reload();
      }).catch((error) => {
        dispatch(getSubjectsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};
