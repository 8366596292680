import {
    ADD_NEW_SUBSCRIPTIONS_SUCCES,ADD_NEW_SUBSCRIPTIONS_FAIL

} from '../actions';

const INIT_STATE = {
    subscriptions: [],
    loading: false,
    error: '',
    newSubscriptions :[]
};

export default (state = INIT_STATE, action) => {
    console.log('action',action);
    switch (action.type) {

        case ADD_NEW_SUBSCRIPTIONS_SUCCES:
            return {
                ...state,
                newSubscriptions: action.payload
            }
        case ADD_NEW_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return { ...state };
    }
};