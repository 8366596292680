import {
    TOPICS_GET_DETAILS,
    TOPICS_GET_DETAILS_SUCCESS,
    TOPICS_GET_DETAILS_ERROR,
    TOPICS_UPLOAD_FILE_DATA_SUCCESS,
    TOPICS_UPLOAD_FILE_DATA_ERROR,
    TOPICS_ADD_NEW,
    TOPICS_UPLOAD_FILE_PROGRESS,
    TOPICS_EMPTY,
    TOPICS_UPDATE
} from '../actions';

const INIT_STATE = {
    topics: [],
    topicsLoading: false,
    topicsError: '',
    uploadFileData: '',
    uploadFileError: '',
    fileProgress: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOPICS_GET_DETAILS:
            return { ...state, loading: true };

        case TOPICS_ADD_NEW:
            return {
                ...state,
                topics: [...state.topics, action.payload]
            }

        case TOPICS_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, topics: action.payload, error: '' };

        case TOPICS_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, topics: [] };

        case TOPICS_UPLOAD_FILE_DATA_SUCCESS:
            return { ...state, uploadFileData: action.payload };

        case TOPICS_UPLOAD_FILE_DATA_ERROR:
            return { ...state, uploadFileError: action.payload, uploadFileData: {} };

        case TOPICS_UPLOAD_FILE_PROGRESS:
            return { ...state, fileProgress: action.payload };
        case TOPICS_EMPTY:
            return { ...state, loading: false, error: '', topics: [] };
        case TOPICS_UPDATE: {
            const updatedItems = state.topics.map(topic => {
                if (topic.topicId === action.payload.topicId) {
                    return { ...topic, ...action.payload }
                }
                return topic
            })
            return {
                ...state,
                topics: updatedItems

            }
        }

        default:
            return { ...state };
    }
};