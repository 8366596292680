import {
    SUBSCRIPTION_MGMT_ADD_NEW,
    SUBSCRIPTION_MGMT_GET_DETAILS_SUCCESS,
    SUBSCRIPTION_MGMT_GET_DETAILS_ERROR,
    SUBSCRIPTION_MGMT_GET_DETAILS,
    SUBSCRIPTION_MGMT_UPDATE,
    SUBSCRIPTION_MGMT_DELETE
} from '../actions';

const INIT_STATE = {
    priceDetails: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SUBSCRIPTION_MGMT_GET_DETAILS:
            return { ...state, loading: true };

        case SUBSCRIPTION_MGMT_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, priceDetails: action.payload, error: '' };

        case SUBSCRIPTION_MGMT_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, priceDetails: [] };

        case SUBSCRIPTION_MGMT_ADD_NEW:
            return {
                ...state,
                priceDetails: [...state.priceDetails, action.payload]
            }
        case SUBSCRIPTION_MGMT_DELETE:
            return {
                ...state,
                priceDetails: state.priceDetails.filter(priceDetail => priceDetail.feesId !== action.payload)

            }
        case SUBSCRIPTION_MGMT_UPDATE: {
            const updatedItems = state.priceDetails.map(priceDetail => {
                if (priceDetail.feesId === action.payload.feesId) {
                    return { ...priceDetail, ...action.payload }
                }
                return priceDetail
            })
            return {
                ...state,
                priceDetails: updatedItems

            }
        }


        default:
            return { ...state };
    }
};