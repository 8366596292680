/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  CLASSES_GET_DETAILS,
  CLASSES_GET_DETAILS_SUCCESS,
  CLASSES_GET_DETAILS_ERROR,
  CLASSES_ADD_NEW,
  CLASSES_DELETE,
  CLASSES_GET_DETAILS_BY_BOARD,
  CLASSES_UPDATE,
  CLASSES_EMPTY
} from '../actions';
import { toast } from 'react-toastify';

export const getClassesDetails = () => ({
  type: CLASSES_GET_DETAILS
});

export const emptyClasses = () => ({
  type: CLASSES_EMPTY
});

export const getClassesDetailsByBoard = (item) => ({
  type: CLASSES_GET_DETAILS_BY_BOARD,
  payload: item
});

export const actionAddNewClassDetails = (item) => ({
  type: CLASSES_ADD_NEW,
  payload: item
});

export const actionDeleteClassDetails = (itemId) => ({
  type: CLASSES_DELETE,
  payload: itemId
});

export const getClassesDetailsSuccess = (items) => ({
  type: CLASSES_GET_DETAILS_SUCCESS,
  payload: items
});

export const getClassesDetailsError = (error) => ({
  type: CLASSES_GET_DETAILS_ERROR,
  payload: error,
});

export const actionUpdateClassesDetails = (item) => ({
  type: CLASSES_UPDATE,
  payload: item
});

// Here as per React-Redux we need to return only objects (i.e. Action objects)
// But, with the help of REDUX-THUNK, we can return a function instead of an object like below.
// And, we can access the function in the Component directly....
export const fetchClassesDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {

    dispatch(getClassesDetails());

    return axios.get(baseUrl + endpoints.getClasses,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getClassesDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//post
export const saveClassDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.postClasses, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionAddNewClassDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 3000 })
        window.location.reload();
      }).catch((error) => {
        //console.log(error);
        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//Delete
export const deleteClassDetails = (deleteClassData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.delete(baseUrl + endpoints.deleteClasses, { data: deleteClassData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionDeleteClassDetails(data.response.data.classId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        //console.log(error);
        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
  // return (dispatch, getState, { axios, baseUrl, endpoints }) => {
  //   console.log(getState());
  //   console.log(baseUrl, endpoints);
  //   console.log(postData);
  // }
};

//get classesbyboard
export const fetchClassesDetailsByBoard = (boardData) => {

  console.log("boardData", boardData.boardId);
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {

    dispatch(getClassesDetailsByBoard());

    return axios.post(baseUrl + endpoints.getClassesByBoard, { "boardId": parseInt(boardData.boardId) },headers)
      .then(response => {

        return response.data;
      }).then(data => {
        dispatch(getClassesDetailsSuccess(data.response.data));
      }).catch(() => {

        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//put
export const updateClassesDetails = (postData) => {
  delete postData.boardName;
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.updateClasses, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionUpdateClassesDetails(data.response.data));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
        window.location.reload();
      }).catch((error) => {
        dispatch(getClassesDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};
