import {
    UPLOADTEST_GET_DETAILS,
    UPLOADTEST_GET_DETAILS_SUCCESS,
    UPLOADTEST_GET_DETAILS_ERROR,
    UPLOADTEST_DELETE
} from '../actions';

const INIT_STATE = {
    Uploadtestresult: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOADTEST_GET_DETAILS:
            return { ...state, loading: true };

        case UPLOADTEST_GET_DETAILS_SUCCESS:
            return { ...state, loading: false, Uploadtestresult: action.payload, error: '' };

        case UPLOADTEST_GET_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload, Uploadtestresult: [] };

        case UPLOADTEST_DELETE:
            return {
                ...state,
                Uploadtestresult: state.Uploadtestresult.filter(Uploadtestresultid => Uploadtestresultid.topicId !== action.payload)

            }

        // case BOARDS_DELETE:
        //     return {
        //         ...state,
        //         boards: state.boards.filter(board => board.boardId !== action.payload)

        //     }



        default:
            return { ...state };
    }
};