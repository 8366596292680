import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
// import todoApp from './todo/reducer';
// import chatApp from './chat/reducer';
// import surveyListApp from './surveyList/reducer';
// import surveyDetailApp from './surveyDetail/reducer';
import boardsApp from './boards/reducer';
import classList from './classes/reducer';
import subjectList from './subjects/reducer';
import topicsList from './topics/reducer';
import chaptersList from './chapters/reducer';
import SubscriptionManagement from './subscriptionmgmt/reducer';
import SubscriptionManagementOffer from './subscriptionmgmtoffer/reducer';
import AllStudentsApp from './students/reducer';
import categoriesApp from './contentmanagement/reducer';
import NotificationApp from './notifications/reducer';
import AddPaperApp from './addpaper/reducer';
import Uploadtests from './uploadtest/reducer';
import uploadBulkSubscription from './uploadBulkSubscription/reducer';
import Admins from './admin/reducer';
import GenerateUrlApp from "./generateUrl/reducer";

const toDoReducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const reducers = combineReducers({
  settings,
  menu,
  authUser,
  todos: toDoReducer,
  boardsApp,
  classList,
  subjectList,
  topicsList,
  chaptersList,
  SubscriptionManagement,
  SubscriptionManagementOffer,
  AllStudentsApp,
  categoriesApp,
  NotificationApp,
  AddPaperApp,
  Uploadtests,
  uploadBulkSubscription,
  Admins,
  GenerateUrlApp,
});

export default reducers;
