/* eslint-disable import/no-cycle */

import { Form } from "formik";

// Boards
export const BOARDS_GET_DETAILS = 'BOARDS_GET_DETAILS';
export const BOARDS_GET_DETAILS_SUCCESS = 'BOARDS_GET_DETAILS_SUCCESS';
export const BOARDS_GET_DETAILS_ERROR = 'BOARDS_GET_DETAILS_ERROR';
export const BOARDS_ADD_NEW = 'BOARDS_ADD_NEW';
export const BOARDS_DELETE = 'BOARDS_DELETE';
export const BOARDS_UPDATE = 'BOARDS_UPDATE';
export const BOARDS_EMPTY = 'BOARDS_EMPTY';
export const DEL_SUBSCRIPTION_SUCCESS = 'DEL_SUBSCRIPTION_SUCCESS';

// Classes
export const CLASSES_GET_DETAILS = "CLASSES_GET_DETAILS";
export const CLASSES_GET_DETAILS_BY_BOARD = "CLASSES_GET_DETAILS_BY_BOARD";
export const CLASSES_GET_DETAILS_SUCCESS = "CLASSES_GET_DETAILS_SUCCESS";
export const CLASSES_GET_DETAILS_ERROR = "CLASSES_GET_DETAILS_ERROR";
export const CLASSES_ADD_NEW = "CLASSES_ADD_NEW";
export const CLASSES_UPDATE = "CLASSES_UPDATE";
export const CLASSES_DELETE = "CLASSES_DELETE";
export const CLASSES_EMPTY = "CLASSES_EMPTY";

// Subjects
export const SUBJECTS_GET_DETAILS = "SUBJECTS_GET_DETAILS";
export const SUBJECTS_GET_DETAILS_SUCCESS = "SUBJECTS_GET_DETAILS_SUCCESS";
export const SUBJECTS_GET_DETAILS_ERROR = "SUBJECTS_GET_DETAILS_ERROR";
export const SUBJECTS_ADD_NEW = "SUBJECTS_ADD_NEW";
export const SUBJECTS_DELETE = "SUBJECTS_DELETE";
export const SUBJECTS_UPDATE = "SUBJECTS_UPDATE";
export const SUBJECTS_EMPTY = "SUBJECTS_EMPTY";

// Topics
export const TOPICS_GET_DETAILS = "TOPICS_GET_DETAILS";
export const TOPICS_GET_DETAILS_SUCCESS = "TOPICS_GET_DETAILS_SUCCESS";
export const TOPICS_GET_DETAILS_ERROR = "TOPICS_GET_DETAILS_ERROR";
export const TOPICS_UPLOAD_FILE_DATA_SUCCESS =
  "TOPICS_UPLOAD_FILE_DATA_SUCCESS";
export const TOPICS_UPLOAD_FILE_DATA_ERROR = "TOPICS_UPLOAD_FILE_DATA_ERROR";
export const TOPICS_ADD_NEW = "TOPICS_ADD_NEW";
export const TOPICS_UPLOAD_FILE_PROGRESS = "TOPICS_UPLOAD_FILE_PROGRESS";
export const TOPICS_DELETE = "TOPICS_DELETE";
export const TOPICS_UPDATE = "TOPICS_UPDATE";
export const TOPICS_EMPTY = "TOPICS_EMPTY";

// Chapters
export const CHAPTERS_GET_DETAILS = "CHAPTERS_GET_DETAILS";
export const CHAPTERS_GET_DETAILS_SUCCESS = "CHAPTERS_GET_DETAILS_SUCCESS";
export const CHAPTERS_GET_DETAILS_ERROR = "CHAPTERS_GET_DETAILS_ERROR";
export const CHAPTERS_ADD_NEW = "CHAPTERS_ADD_NEW";
export const CHAPTERS_DELETE = "CHAPTERS_DELETE";
export const CHAPTERS_UPDATE = "CHAPTERS_UPDATE";
export const CHAPTERS_EMPTY = "CHAPTERS_EMPTY";

//Subscription Mgmt
export const SUBSCRIPTION_MGMT_ADD_NEW = "SUBSCRIPTION_MGMT_ADD_NEW";
export const SUBSCRIPTION_MGMT_GET_DETAILS_SUCCESS =
  "SUBSCRIPTION_MGMT_GET_DETAILS_SUCCESS";
export const SUBSCRIPTION_MGMT_GET_DETAILS_ERROR =
  "SUBSCRIPTION_MGMT_GET_DETAILS_ERROR";
export const SUBSCRIPTION_MGMT_GET_DETAILS = "SUBSCRIPTION_MGMT_GET_DETAILS";
export const SUBSCRIPTION_MGMT_DELETE = "SUBSCRIPTION_MGMT_DELETE";
export const SUBSCRIPTION_MGMT_UPDATE = "SUBSCRIPTION_MGMT_UPDATE";

export const SUBSCRIPTION_GET_DETAILS_BY_STUDENT ='SUBSCRIPTION_GET_DETAILS_BY_STUDENT';
export const SUBSCRIPTION_GET_DETAILS_BY_STUDENT_SUCCESS ='SUBSCRIPTION_GET_DETAILS_BY_STUDENT_SUCCESS';
export const SUBSCRIPTION_GET_DETAILS_BY_STUDENT_FAIL = 'SUBSCRIPTION_GET_DETAILS_BY_STUDENT_FAIL';

//Subscription Mgmt Offer
export const SUBSCRIPTION_MGMT_OFFER_ADD_NEW =
  "SUBSCRIPTION_MGMT_OFFER_ADD_NEW";
export const SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_SUCCESS =
  "SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_SUCCESS";
export const SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_ERROR =
  "SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_ERROR";
export const SUBSCRIPTION_MGMT_OFFER_GET_DETAILS =
  "SUBSCRIPTION_MGMT_OFFER_GET_DETAILS";
export const SUBSCRIPTION_MGMT_OFFER_DELETE = "SUBSCRIPTION_MGMT_OFFER_DELETE";
export const SUBSCRIPTION_MGMT_OFFER_UPDATE = "SUBSCRIPTION_MGMT_OFFER_UPDATE";


//Notifications
export const NOTIFICATIONS_GET_DETAILS_SUCCESS =
  "NOTIFICATIONS_GET_DETAILS_SUCCESS";
export const NOTIFICATIONS_GET_DETAILS_ERROR =
  "NOTIFICATIONS_GET_DETAILS_ERROR";
export const NOTIFICATIONS_ADD_DETAILS_SUCCESS =
  "NOTIFICATIONS_ADD_DETAILS_SUCCESS";
export const NOTIFICATIONS_DELETE = "NOTIFICATIONS_DELETE";

//Bulk Subscription
export const ADD_NEW_SUBSCRIPTIONS_SUCCES = 'ADD_NEW_SUBSCRIPTIONS_SUCCES';
export const ADD_NEW_SUBSCRIPTIONS_FAIL = 'ADD_NEW_SUBSCRIPTIONS_FAIL';


//Assignments(Papers)

export const PAPER_ADD_NEW = 'PAPER_ADD_NEW';
export const GET_ALL_PREVIOUS_PAPERS_SUCCES = 'GET_ALL_PREVIOUS_PAPERS_SUCCES';
export const GET_ALL_PREVIOUS_PAPERS_FAIL = 'GET_ALL_PREVIOUS_PAPERS_FAIL';
export const GET_ALL_PREVIOUS_PAPERS = 'GET_ALL_PREVIOUS_PAPERS';
export const PAPERS_GET_DETAILS_ERROR = 'PAPERS_GET_DETAILS_ERROR';
export const PAPERS_DELETE='PAPERS_DELETE';
export const PAPER_EDIT ='PAPER_EDIT';
export const PAPER_EDIT_SUCCESS = "PAPER_EDIT_SUCCESS";
export const PAPER_EDIT_FAIL = "PAPER_EDIT_SUCCESS";


/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const STUDENT_SEARCH_BY_MOBILE = "STUDENT_SEARCH_BY_MOBILE";
/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// /* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const ALL_STUDENTS_INFO = "ALL_STUDENTS_INFO";
export const ALL_STUDENTS_INFO_SUCCESS = "ALL_STUDENTS_INFO_SUCCESS";
export const ALL_STUDENTS_INFO_ERROR = "ALL_STUDENTS_INFO_ERROR";
export const DELETE_STUDENT = "DELETE_STUDENT";

export const CATEGORY_GET_DETAILS = "CATEGORY_GET_DETAILS";
export const CATEGORY_GET_DETAILS_SUCCESS = "CATEGORY_GET_DETAILS_SUCCESS";
export const CATEGORY_GET_DETAILS_ERROR = "CATEGORY_GET_DETAILS_ERROR";
export const ADD_NEW_CONTENT = "ADD_NEW_CONTENT";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const CONTENT_GET_DETAILS = "CONTENT_GET_DETAILS";
export const CONTENT_GET_DETAILS_SUCCESS = "CONTENT_GET_DETAILS_SUCCESS";
export const CONTENT_GET_DETAILS_ERROR = "CONTENT_GET_DETAILS_ERROR";

// get upload test
export const UPLOADTEST_GET_DETAILS = 'UPLOADTEST_GET_DETAILS';
export const UPLOADTEST_GET_DETAILS_SUCCESS = 'UPLOADTEST_GET_DETAILS_SUCCESS';
export const UPLOADTEST_GET_DETAILS_ERROR = 'UPLOADTEST_GET_DETAILS_ERROR';
export const UPLOADTEST_DELETE = 'UPLOADTEST_DELETE';


// Admin Details

export const ADD_ADMIN_DETAILS='ADD_ADMIN_DETAILS';
export const GET_ADMIN_DETAILS='GET_ADMIN_DETAILS';
export const ADMINDETAILS_SUCCESS= 'ADMINDETAILS_SUCCESS';
export const ADMINDETAILS_FAIL = 'ADMINDETAILS_SUCCESS';
export const GET_ADMINDETAILS_FAIL = 'GET_ADMINDETAILS_FAIL';
export const   GET_ADMINDETAILS_SUCCES ='GET_ADMINDETAILS_SUCCES';
export const DELETE_ADMINDETAILS_FAIL='DELETE_ADMINDETAILS_FAIL';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';

// Generate URLs for media
export const GENERATE_URL_INFO = "GENERATE_URL_INFO";
export const GENERATE_URL_SUCCESS = "GENERATE_URL_SUCCESS";
export const GENERATE_URL_ERROR = "GENERATE_URL_ERROR";
export const GENERATE_URL_PROGRESS = "GENERATE_URL_PROGRESS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
// export * from './todo/actions';
// export * from './chat/actions';
// export * from './surveyList/actions';
// export * from './surveyDetail/actions';
export * from './boards/actions';
export * from './classes/actions';
export * from './subjects/actions';
export * from './topics/actions';

export * from './chapters/actions';
export * from './subscriptionmgmt/actions';
export * from './subscriptionmgmtoffer/actions';

export * from './students/actions';
export * from './contentmanagement/actions';
export * from './addpaper/actions';
export * from './uploadtest/actions';
export * from './uploadBulkSubscription/actions';
export * from './admin/actions';
export * from "./generateUrl/actions";

