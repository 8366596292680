import {
  GENERATE_URL_INFO,
  GENERATE_URL_SUCCESS,
  GENERATE_URL_ERROR,
  GENERATE_URL_PROGRESS,
} from "../actions";

const INIT_STATE = {
  generateUrlInfo: "",
  generateUrlSuccess: "",
  generateUrlError: "",
  generateUrlProgress: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GENERATE_URL_INFO:
      return { ...state };

    case GENERATE_URL_SUCCESS:
      return {
        ...state,
        generateUrlInfo: action.payload,
        generateUrlError: "",
      };

    case GENERATE_URL_ERROR:
      return {
        ...state,
        generateUrlInfo: "",
        generateUrlError: action.payload,
      };

    case GENERATE_URL_PROGRESS:
      return { ...state, generateUrlProgress: action.payload };

    default:
      return { ...state };
  }
};
