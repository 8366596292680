import {
    CHAPTERS_GET_DETAILS,
    CHAPTERS_GET_DETAILS_ERROR,
    CHAPTERS_GET_DETAILS_SUCCESS,
    CHAPTERS_ADD_NEW,
    CHAPTERS_DELETE,
    CHAPTERS_UPDATE,
    CHAPTERS_EMPTY
} from '../actions';

const INIT_STATE = {
    chapters: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAPTERS_GET_DETAILS:
            return { ...state };

        case CHAPTERS_ADD_NEW:
            return {
                ...state,
                chapters: [...state.chapters, action.payload]
            }

        case CHAPTERS_GET_DETAILS_SUCCESS:
            return { ...state, chapters: action.payload, error: '' };

        case CHAPTERS_GET_DETAILS_ERROR:
            return { ...state, chapters: [] };

        case CHAPTERS_DELETE:
            return {
                ...state,
                chapters: state.chapters.filter(chapter => chapter.chapterId !== action.payload)

            }
        case CHAPTERS_UPDATE: {
            const updatedItems = state.chapters.map(chapter => {
                if (chapter.chapterId === action.payload.chapterId) {
                    return { ...chapter, ...action.payload }
                }
                return chapter
            })
            return {
                ...state,
                chapters: updatedItems

            }
        }
        case CHAPTERS_EMPTY:
            return { ...state, loading: false, error: '', chapters: [] };


        default:
            return { ...state };
    }
};