/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';

import {
  CATEGORY_GET_DETAILS,
  CATEGORY_GET_DETAILS_SUCCESS,
  CATEGORY_GET_DETAILS_ERROR,
  ADD_NEW_CONTENT,
  CONTENT_GET_DETAILS,
  CONTENT_GET_DETAILS_SUCCESS,
  CONTENT_GET_DETAILS_ERROR,
  DELETE_CONTENT,
  UPDATE_CONTENT

} from '../actions';

export const getCategoryDetails = () => ({
  type: CATEGORY_GET_DETAILS
});

export const getCategoryDetailsSuccess = (items) => ({
  type: CATEGORY_GET_DETAILS_SUCCESS,
  payload: items
});
export const getCategoryDetailsError = (error) => ({
  type: CATEGORY_GET_DETAILS_ERROR,
  payload: error,
});

export const getContentDetails = () => ({
  type: CONTENT_GET_DETAILS
});

export const getContentDetailsSuccess = (items) => ({
  type: CONTENT_GET_DETAILS_SUCCESS,
  payload: items
});

export const getContentDetailsError = (error) => ({
  type: CONTENT_GET_DETAILS_ERROR,
  payload: error,
});


export const addNewContentDetails = (item) => ({
  type: ADD_NEW_CONTENT,
  payload: item
});

export const deleteContentDetailsAction = (itemId) => ({
  type: DELETE_CONTENT,
  payload: itemId
});

export const actionUpdateContentDetails = (item) => ({
  type: UPDATE_CONTENT,
  payload: item
});
// Here as per React-Redux we need to return only objects (i.e. Action objects)
// But, with the help of REDUX-THUNK, we can return a function instead of an object like below.
// And, we can access the function in the Component directly....
export const fetchCategoryDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {

    dispatch(getCategoryDetails());

    return axios.get(baseUrl + endpoints.categories)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getCategoryDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getCategoryDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//post
export const addNewContent = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints }) => {
    return axios.post(baseUrl + endpoints.addNewContent, postData)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(addNewContentDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 3000 })
      }).catch((error) => {
        dispatch(getContentDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//Get content details


export const fetchContentDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {

    dispatch(getCategoryDetails());

    return axios.get(baseUrl + endpoints.getcontents,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getContentDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getContentDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};
//Delete
export const deleteContentDetails = (deleteContentData) => {
  
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.delete(baseUrl + endpoints.deletecontent, { data: deleteContentData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deleteContentDetailsAction(data.response.data.contentId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getContentDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//put
export const updateContentDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.updatecontent, postData, headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionUpdateContentDetails(data.response.data));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getContentDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};