/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  SUBSCRIPTION_MGMT_OFFER_ADD_NEW,
  SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_SUCCESS,
  SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_ERROR,
  SUBSCRIPTION_MGMT_OFFER_GET_DETAILS,
  SUBSCRIPTION_MGMT_OFFER_DELETE,
  SUBSCRIPTION_MGMT_OFFER_UPDATE
} from '../actions';
import { toast } from 'react-toastify';

export const getSubscriptionMgmtOfferDetails = () => ({
  type: SUBSCRIPTION_MGMT_OFFER_GET_DETAILS
});

export const actionAddNewSubscriptionmgmtOfferDetails = (item) => ({
  type: SUBSCRIPTION_MGMT_OFFER_ADD_NEW,
  payload: item
});

export const getSubscriptionmgmtOfferDetailsSuccess = (items) => ({
  type: SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_SUCCESS,
  payload: items
});
export const getSubscriptionmgmtOfferDetailsError = (error) => ({
  type: SUBSCRIPTION_MGMT_OFFER_GET_DETAILS_ERROR,
  payload: error,
});

export const deleteSubscriptionmgmtOfferDetailsAction = (itemId) => ({
  type: SUBSCRIPTION_MGMT_OFFER_DELETE,
  payload: itemId
});

export const actionUpdateSubscriptionmgmtOfferDetails = (item) => ({
  type: SUBSCRIPTION_MGMT_OFFER_UPDATE,
  payload: item
});
//post
export const saveSubscriptionmgmtOfferDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.post(baseUrl + endpoints.offers, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionAddNewSubscriptionmgmtOfferDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 3000 })
      }).catch((error) => {
        dispatch(getSubscriptionmgmtOfferDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchSubscriptionMgmtOfferDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    dispatch(getSubscriptionMgmtOfferDetails());
    return axios.get(baseUrl + endpoints.offers,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getSubscriptionmgmtOfferDetailsSuccess(data.response.data));
        console.log(data.response.data)
      }).catch(() => {
        dispatch(getSubscriptionmgmtOfferDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//Delete
export const deleteSubscriptionmgmtOfferDetails = (deleteOfferData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.delete(baseUrl + endpoints.offers, { data: deleteOfferData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deleteSubscriptionmgmtOfferDetailsAction(data.response.data.feesId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
        window.location.reload();
      }).catch((error) => {
        dispatch(getSubscriptionmgmtOfferDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//put
export const updateSubscriptionmgmtOfferDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.offers, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionUpdateSubscriptionmgmtOfferDetails(data.response.data));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getSubscriptionmgmtOfferDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};


