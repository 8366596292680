/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import {
    CHAPTERS_GET_DETAILS,
    CHAPTERS_GET_DETAILS_ERROR,
    CHAPTERS_GET_DETAILS_SUCCESS,
    CHAPTERS_ADD_NEW,
    CHAPTERS_DELETE,
    CHAPTERS_UPDATE,
    CHAPTERS_EMPTY
} from '../actions';

export const getChaptersDetails = () => ({
    type: CHAPTERS_GET_DETAILS
});

export const emptyChapters = () => ({
    type: CHAPTERS_EMPTY
});
export const getChaptersDetailsSuccess = (items) => ({
    type: CHAPTERS_GET_DETAILS_SUCCESS,
    payload: items
});

export const getChapetersDetailsError = (error) => ({
    type: CHAPTERS_GET_DETAILS_ERROR,
    payload: error,
});

export const addChapter = (chapter) => ({
    type: CHAPTERS_ADD_NEW,
    payload: chapter
});
export const deleteChapterDetailsAction = (itemId) => ({
    type: CHAPTERS_DELETE,
    payload: itemId
});
export const actionUpdateChaptersDetails = (item) => ({
    type: CHAPTERS_UPDATE,
    payload: item
});

export const fetchChaptersDetails = () => {
    return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
        return axios.get(baseUrl + endpoints.getChapters,headers)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getChaptersDetailsSuccess(data.response.data));
            }).catch(() => {
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

export const saveChaptersDetails = (chapterData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
        return axios.post(baseUrl + endpoints.addChapters, chapterData,headers)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(addChapter(data.response.data[0]));
                toast.configure();
                toast.success('Successfully Added', { autoClose: 2000 });
                window.location.reload();
            }).catch(() => {
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

export const fetchChaptersDetailsBySubject = (subjectData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
        return axios.post(baseUrl + endpoints.getChaptersBySubject, { "subjectId": parseInt(subjectData.subjectId) },headers)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(getChaptersDetailsSuccess(data.response.data));
            }).catch(() => {
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

//Delete
export const deleteChapterDetails = (deleteChapterData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
        return axios.delete(baseUrl + endpoints.deleteChapters, { data: deleteChapterData },headers)
            .then(response => {
                //fetchBoardsDetails()();
                return response.data;
            }).then(data => {
                dispatch(deleteChapterDetailsAction(data.response.data.chapterId));
                toast.configure()
                toast.success('Successfully Deleted', { autoClose: 2000 })
            }).catch((error) => {
                //console.log(error);
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};

//put
export const updateChaptersDetails = (postData) => {
    delete postData.boardName;
    delete postData.className;
    delete postData.subjectName;
    delete postData.subjectImageUrl;
    return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
        return axios.put(baseUrl + endpoints.updateChapters, postData,headers)
            .then(response => {
                return response.data;
            }).then(data => {
                dispatch(actionUpdateChaptersDetails(data.response.data));
                toast.configure()
                toast.success('Successfully Updated', { autoClose: 2000 })
                window.location.reload();
            }).catch((error) => {
                dispatch(getChapetersDetailsError('Something went wrong. Try again after sometime.'));
            });
    }
};