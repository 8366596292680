
// INFI LEARN PROJECT CONSTANTS
// import { UserRole } from "../helpers/authHelper";
import { BASE_URL } from "../env-config";
import { getCurrentUser} from '../helpers/Utils';
export const baseUrl = BASE_URL; //'/subj-mgmt/api/v1/';
//export const baseUrl = 'http://localhost:3003/subj-mgmt/api/v1/'
//export const baseUrl = 'https://infelearn.com/subj-mgmt/api/v1/';

export const baseUrl2 = 'http://infelearn.com/subj-mgmt/api/v1/';
export const baseUrl1 = 'http://infelearn.com/subj-mgmt/api/v1/';

export const endpoints = {
  getBoards: 'boards',
  postBoards: 'boards',
  updateBoards: 'boards',
  deleteBoards: 'boards',
  //Classes
  getClasses: 'classes',
  postClasses: 'classes',
  updateClasses: 'classes',
  deleteClasses: 'classes',
  getClassesByBoard: 'classesbyboards',
  //Subjects
  getSubjects: 'subjects',
  postSubjects: 'subjects',
  updateSubjects: 'subjects',
  deleteSubjects: 'subjects',
  getSubjectsByClass: 'subjectsbyclass',
  //Topics
  getTopics: 'topics',
  postTopics: 'topics',
  updateTopics: 'topics',
  deleteTopics: 'topics',
  postTopics: 'topics',
  getTopicsByChapter: 'topicsbychapters',
  updateTopicsTest: 'uploadexcel',

  // upload files
  uploadFile: 'upload',
  bulkSubupload:'bulkSubscriptions',
  subscriptionbystudent :'subscriptionbystudent',
  exportSubscriptions :'exportSubscriptions',


  getChapters: 'chapters',
  addChapters: 'chapters',
  deleteChapters: 'chapters',
  updateChapters: 'chapters',
  getChaptersBySubject: 'getchapterbysubjects',
  

  //Subscription Mgmt
  classesfeesdetails: 'classesfeesdetails',
  offers: 'offers',
  subscription :'subscription',


  //Notifications
  getNotifications: 'notifications/admin',
  notifications: 'notifications',

  getAllStudents: 'students',
  getAllSubscriptions : 'student/subscriptiondetails',
  categories: 'content/categories',
  addNewContent: 'addcontent',
  getcontents: 'getcontents',
  deletecontent: 'deletecontent',
  updatecontent: 'updatecontent',
  updateTopicName: 'topics',
  addpaper: 'addpaper',
  papers: 'papers',
  deletepaper : 'deletepaper',
  getAllStudentsByMobile: 'admin/searchstudent',
  deleteTests: 'deleteallquestions',
  getallquestions: 'getallquestions',
  //admin
  admin : 'admin',
  adminlogin : 'adminlogin',
  deleteStudent: 'admin/deletestudent'
};
const CurrentUser = localStorage.getItem('gogo_current_user') != null ? JSON.parse(localStorage.getItem('gogo_current_user')) : null;

export const headers = {
  headers: {
  'Content-Type': 'application/json',
  'CurrentUser': CurrentUser && CurrentUser.adminEmail
  }
};
export const uploadFileHeaders = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'CurrentUser': CurrentUser && CurrentUser.adminEmail
  }
}
export const projectConfig = {
  baseUrl,
  endpoints,
  headers,
  uploadFileHeaders
};
/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/login/balloon.jpg',
  date: 'Last seen today 15:24',
  role: 'Admin'
}


export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
