/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  SUBSCRIPTION_MGMT_ADD_NEW,
  SUBSCRIPTION_MGMT_GET_DETAILS_SUCCESS,
  SUBSCRIPTION_MGMT_GET_DETAILS_ERROR,
  SUBSCRIPTION_MGMT_GET_DETAILS,
  SUBSCRIPTION_MGMT_DELETE,
  SUBSCRIPTION_MGMT_UPDATE
} from '../actions';
import { toast } from 'react-toastify';

export const getSubscriptionMgmtDetails = () => ({
  type: SUBSCRIPTION_MGMT_GET_DETAILS
});

export const actionAddNewSubscriptionmgmtDetails = (item) => ({
  type: SUBSCRIPTION_MGMT_ADD_NEW,
  payload: item
});

export const getSubscriptionmgmtDetailsSuccess = (items) => ({
  type: SUBSCRIPTION_MGMT_GET_DETAILS_SUCCESS,
  payload: items
});
export const getSubscriptionmgmtDetailsError = (error) => ({
  type: SUBSCRIPTION_MGMT_GET_DETAILS_ERROR,
  payload: error,
});

export const deletePriceDetailsAction = (itemId) => ({
  type: SUBSCRIPTION_MGMT_DELETE,
  payload: itemId
});

export const actionUpdatePriceDetails = (item) => ({
  type: SUBSCRIPTION_MGMT_UPDATE,
  payload: item
});

//post
export const saveSubscriptionmgmtDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.classesfeesdetails, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionAddNewSubscriptionmgmtDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 3000 })
      }).catch((error) => {
        dispatch(getSubscriptionmgmtDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchSubscriptionMgmtDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    dispatch(getSubscriptionMgmtDetails());
    return axios.get(baseUrl + endpoints.classesfeesdetails,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getSubscriptionmgmtDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getSubscriptionmgmtDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//Delete
export const deletePriceDetails = (deletePriceData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.delete(baseUrl + endpoints.classesfeesdetails, { data: deletePriceData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deletePriceDetailsAction(data.response.data.feesId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getSubscriptionmgmtDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//put
export const updatePriceDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.classesfeesdetails, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionUpdatePriceDetails(data.response.data));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getSubscriptionmgmtDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};
