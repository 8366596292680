/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';

import {

  PAPER_ADD_NEW,GET_ALL_PREVIOUS_PAPERS_SUCCES,GET_ALL_PREVIOUS_PAPERS,GET_ALL_PREVIOUS_PAPERS_FAIL ,PAPERS_DELETE,PAPERS_GET_DETAILS_ERROR
,PAPER_EDIT,PAPER_EDIT_SUCCESS,PAPER_EDIT_FAIL
} from '../actions';

export const addNewPaperDetails = (item) => ({
  type: PAPER_ADD_NEW,
  payload: item
});

export const editPaperDetailsSuccess = (item) => ({
  type: PAPER_EDIT_SUCCESS,
  payload: item
});
export const editPaperDetailsFail = (item) => ({
  type: PAPER_EDIT_FAIL,
  payload: item
});

export const editPaperDetailsAction = (item) => ({
  type: PAPER_EDIT,
  payload: item
});
export const getAllPreviousPapersSucces = (item) => ({
  type: GET_ALL_PREVIOUS_PAPERS_SUCCES,
  payload: item
});
export const getAllPreviousPapers = () => ({
  type: GET_ALL_PREVIOUS_PAPERS,
});

export const getAllPreviousPapersFail = (item) => ({
  type: GET_ALL_PREVIOUS_PAPERS_FAIL,
  payload: item
});

export const deletePaperDetailsAction = (itemId) => ({
  type: PAPERS_DELETE,
  payload: itemId
});
export const getPaperDetailsError = (error) => ({
  type: PAPERS_GET_DETAILS_ERROR,
  payload: error,
});

//Delele 
export const deletePaperDetails = (paperData) => {
    return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
      return axios.delete(baseUrl + endpoints.papers, { data: paperData },headers)
        .then(response => {
          return response.data;
        }).then(data => {
          dispatch(deletePaperDetailsAction(data.response.data.boardId));
          toast.configure()
          toast.success('Successfully Deleted', { autoClose: 2000 })
        }).catch((error) => {
          dispatch(getPaperDetailsError('Something went wrong. Try again after sometime.'));
        });
    }
  };


//Get All previous papers
export const getAllPapers = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    dispatch(getAllPreviousPapers());
    return axios.get(baseUrl + endpoints.papers,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        console.log('data',data);
        dispatch(getAllPreviousPapersSucces(data.response.data));
        toast.configure()
       // toast.success('Successfully Added', { autoClose: 2000 })
       // window.location.reload();
      }).catch((error) => {
        dispatch(getAllPreviousPapersFail('Something went wrong. Try again after sometime.'));
      });
  }}

  
//post
export const savePapersDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.post(baseUrl + endpoints.addpaper, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(addNewPaperDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 2000 })
        window.location.reload();
      }).catch(() => {
        dispatch(getAllPreviousPapersFail('Something went wrong. Try again after sometime.'));
      });
  }
};

// Edit Paper
export const editPaperDetails = (postData) => {
console.log('edit info',postData);
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.put(baseUrl + endpoints.papers, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(editPaperDetailsSuccess(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
        window.location.reload();
      }).catch(() => {
        dispatch(editPaperDetailsFail('Something went wrong. Try again after sometime.'));
      });
};
};