/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';

import {
  BOARDS_GET_DETAILS,
  BOARDS_GET_DETAILS_SUCCESS,
  BOARDS_GET_DETAILS_ERROR,
  BOARDS_ADD_NEW,
  BOARDS_DELETE,
  BOARDS_UPDATE,
  BOARDS_EMPTY,
  NOTIFICATIONS_GET_DETAILS_SUCCESS, NOTIFICATIONS_GET_DETAILS_ERROR,
  NOTIFICATIONS_ADD_DETAILS_SUCCESS, NOTIFICATIONS_DELETE
} from '../actions';

export const getBoardsDetails = () => ({
  type: BOARDS_GET_DETAILS
});

export const emptyBoards = () => ({
  type: BOARDS_EMPTY
});

export const addNewBoardsDetails = (item) => ({
  type: BOARDS_ADD_NEW,
  payload: item
});

export const actionUpdateBoardsDetails = (item) => ({
  type: BOARDS_UPDATE,
  payload: item
});

export const deleteBoardDetailsAction = (itemId) => ({
  type: BOARDS_DELETE,
  payload: itemId
});

export const getBoardsDetailsSuccess = (items) => ({
  type: BOARDS_GET_DETAILS_SUCCESS,
  payload: items
});

export const getBoardsDetailsError = (error) => ({
  type: BOARDS_GET_DETAILS_ERROR,
  payload: error,
});

export const getNotificationsSuccess = (items) => ({
  type: NOTIFICATIONS_GET_DETAILS_SUCCESS,
  payload: items
});

export const getNotificationsError = (error) => ({
  type: NOTIFICATIONS_GET_DETAILS_ERROR,
  payload: error,
});

export const addNotifications = (item) => ({
  type: NOTIFICATIONS_ADD_DETAILS_SUCCESS,
  payload: item
});

export const deleteNotifications = (itemId) => ({
  type: NOTIFICATIONS_DELETE,
  payload: itemId
});

// Here as per React-Redux we need to return only objects (i.e. Action objects)
// But, with the help of REDUX-THUNK, we can return a function instead of an object like below.
// And, we can access the function in the Component directly....
export const fetchBoardsDetails = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {

    dispatch(getBoardsDetails());
    return axios.get(baseUrl + endpoints.getBoards,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getBoardsDetailsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getBoardsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//post
export const saveBoardsDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints ,headers  }) => {

    //dispatch(addBoardsDetails());

    return axios.post(baseUrl + endpoints.postBoards, postData,headers)
      .then(response => {
        //fetchBoardsDetails()();
        return response.data;
      }).then(data => {

        dispatch(addNewBoardsDetails(data.response.data[0]));
        toast.configure()
        toast.success('Successfully Added', { autoClose: 3000 })
      }).catch((error) => {

        //console.log(error);
        dispatch(getBoardsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
  // return (dispatch, getState, { axios, baseUrl, endpoints }) => {
  //   console.log(getState());
  //   console.log(baseUrl, endpoints);
  //   console.log(postData);
  // }
};


//Delete
export const deleteBoardsDetails = (deleteBoardData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.delete(baseUrl + endpoints.deleteBoards, { data: deleteBoardData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deleteBoardDetailsAction(data.response.data.boardId));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getBoardsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

//put
export const updateBoardsDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.put(baseUrl + endpoints.updateBoards, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(actionUpdateBoardsDetails(data.response.data));
        toast.configure()
        toast.success('Successfully Updated', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getBoardsDetailsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const fetchNotifications = () => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.get(baseUrl + endpoints.getNotifications,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(getNotificationsSuccess(data.response.data));
      }).catch(() => {
        dispatch(getNotificationsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const saveNotificationDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints,headers }) => {
    return axios.post(baseUrl + endpoints.notifications, postData,headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(addNotifications(data.response.data));
        toast.configure()
        if (data.response.data.notifyid) {
          toast.success('Successfully Added', { autoClose: 2000 })
        } else {
          toast.error(data.response.message, { autoClose: 3000 })
        }
      }).catch((error) => {
        dispatch(getNotificationsError('Something went wrong. Try again after sometime.'));
      });
  }
};

export const deleteNotificationDetails = (postData) => {
  return (dispatch, getState, { axios, baseUrl, endpoints, headers }) => {
    return axios.delete(baseUrl + endpoints.notifications, { data: postData },headers)
      .then(response => {
        return response.data;
      }).then(data => {
        dispatch(deleteNotifications(data.response.data.notifyid));
        toast.configure()
        toast.success('Successfully Deleted', { autoClose: 2000 })
      }).catch((error) => {
        dispatch(getNotificationsError('Something went wrong. Try again after sometime.'));
      });
  }
};